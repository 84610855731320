<template>
  <!-- A C T I O N  B U T T O N S -->
  <div class="mb-12 flex justify-between">
    <div
      class="flex items-center mb-12 text-grey-fp-50 cursor-pointer"
      @click="router.push({ name: routesNames.adminNotificationsList })"
    >
      <AppIcon
        name="arrow-thin-left"
        class="mr-10"
      />
      <p class="text-md">Back to Notifications</p>
    </div>
    <div class="flex items-start justify-end">
      <AppButton
        v-if="stage === 'create'"
        type="primary"
        class="mr-10 px-30"
        @click="onSubmit"
      >
        Save
      </AppButton>
      <AppButton
        v-else
        type="primary"
        class="mr-10 px-30"
        :disabled="disabledAction"
        @click="onSubmit"
      >
        Update
      </AppButton>
    </div>
  </div>

  <!-- F O R M -->
  <form
    class="max-w-7/10"
    @submit.prevent="onSubmit"
  >
    <AppTextarea
      v-model="v$.description.$model"
      label="Message"
      placeholder="Message"
      :error="errorMessages.description"
      maxlength="280"
      class="w-full mb-20"
      @focus="clearErrorField('description')"
    />

    <AppInput
      v-model="v$.externalLink.$model"
      name="externalLink"
      placeholder="https://www.eventbrite.com"
      :error="errorMessages.externalLink"
      @focus="clearErrorField('externalLink')"
    > 
      <template #label>
        <p class="text-dark-cl-20">External Link <span class="text-grey-fp-60">(Optional)</span></p>
      </template>
    </AppInput>
  </form>
</template>

<script lang="ts">
  import { defineComponent, reactive, PropType, toRefs, computed } from 'vue';
  import { useVuelidate } from "@vuelidate/core";
  import { helpers, url } from "@vuelidate/validators";

  import AppIcon from '@/components/stateless/AppIcon.vue';
  import AppButton from '@/components/stateless/AppButton.vue';
  import AppTextarea from '@/components/stateless/AppTextarea.vue';
  import AppInput from '@/components/stateless/AppInput.vue';

  import { IErrorFields, INotificationForm } from '@/types';
  import { clearErrorField, handleSetErrors, validateField } from '@/core/helper-functions';
  import { notificationsFormRules } from '@/views/admin/notifications/notifications-validation-rules';
  import { routesNames, router } from '@/router';

  export default defineComponent({
    name: 'NotificationForm',

    components: { AppButton, AppTextarea, AppIcon, AppInput },

    props: {
      data: {
        type: Object as PropType<INotificationForm>,
        required: true
      },

      stage: {
        type: String,
        validator: (value: string) => ['create', 'edit'].indexOf(value) !== -1,
        default: 'create'
      },

      disabledAction: {
        type: Boolean,
        default: false
      }
    },

    emits: ['submit', 'remove'],

    setup(props, { emit }) {
      const { data } = toRefs(props);

      const errorMessages = reactive<IErrorFields>({ description: '', externalLink: '' });

      const rules = computed<any>(() => ({ 
          ...notificationsFormRules,
          externalLink: data.value.externalLink ? { url: helpers.withMessage('Invalid URL', url) } : {}
        }));

      const v$ = useVuelidate(rules, data.value);

      async function onSubmit() {
        if (await v$.value.$validate()) { emit('submit', {...data.value, externalLink: data.value.externalLink?.trim() || null }); }
        else { handleSetErrors(v$.value.$errors, errorMessages); }
      }

      return {
        errorMessages,
        router,
        routesNames,

        v$,

        onSubmit,
        validateField: (name: string) => validateField(name, v$, errorMessages),
        clearErrorField: (name: string) => clearErrorField(name, errorMessages),
      };
    }

  });
</script>