
  import { defineComponent, reactive, PropType, toRefs, computed } from 'vue';
  import { useVuelidate } from "@vuelidate/core";
  import { helpers, url } from "@vuelidate/validators";

  import AppIcon from '@/components/stateless/AppIcon.vue';
  import AppButton from '@/components/stateless/AppButton.vue';
  import AppTextarea from '@/components/stateless/AppTextarea.vue';
  import AppInput from '@/components/stateless/AppInput.vue';

  import { IErrorFields, INotificationForm } from '@/types';
  import { clearErrorField, handleSetErrors, validateField } from '@/core/helper-functions';
  import { notificationsFormRules } from '@/views/admin/notifications/notifications-validation-rules';
  import { routesNames, router } from '@/router';

  export default defineComponent({
    name: 'NotificationForm',

    components: { AppButton, AppTextarea, AppIcon, AppInput },

    props: {
      data: {
        type: Object as PropType<INotificationForm>,
        required: true
      },

      stage: {
        type: String,
        validator: (value: string) => ['create', 'edit'].indexOf(value) !== -1,
        default: 'create'
      },

      disabledAction: {
        type: Boolean,
        default: false
      }
    },

    emits: ['submit', 'remove'],

    setup(props, { emit }) {
      const { data } = toRefs(props);

      const errorMessages = reactive<IErrorFields>({ description: '', externalLink: '' });

      const rules = computed<any>(() => ({ 
          ...notificationsFormRules,
          externalLink: data.value.externalLink ? { url: helpers.withMessage('Invalid URL', url) } : {}
        }));

      const v$ = useVuelidate(rules, data.value);

      async function onSubmit() {
        if (await v$.value.$validate()) { emit('submit', {...data.value, externalLink: data.value.externalLink?.trim() || null }); }
        else { handleSetErrors(v$.value.$errors, errorMessages); }
      }

      return {
        errorMessages,
        router,
        routesNames,

        v$,

        onSubmit,
        validateField: (name: string) => validateField(name, v$, errorMessages),
        clearErrorField: (name: string) => clearErrorField(name, errorMessages),
      };
    }

  });
