
  import { defineComponent, reactive, ref } from 'vue';
  import { useToast } from "vue-toastification";

  import NotificationForm from '@/views/admin/notifications/components/NofiticationForm.vue';
  import UnsavedChangesModal from '@/components/UnsavedChangesModal.vue';

  import { INotificationForm } from '@/types';
  import { notificationsService } from '@/services';
  import { vuex } from '@/store';
  import { router, routesNames } from '@/router';

  export default defineComponent({
    name: 'CreateNotification',

    components: { NotificationForm, UnsavedChangesModal },

    setup() {
      const toast = useToast();
      const notificationForm = reactive<INotificationForm>({ description: '', externalLink: '' });
      const disabledHandleChanges = ref<boolean>(false);

      async function create(payload: INotificationForm) {
        vuex.setLoading(true);

        await notificationsService.createNotification(payload)
          .then(() => {
            disabledHandleChanges.value = true;
            toast.success('Notification has been created');
            router.push({ name: routesNames.adminNotificationsList });
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      return {
        notificationForm,
        disabledHandleChanges,

        create
      };
    }

  });
