import { required, helpers } from "@vuelidate/validators";


const notificationsFormRules = {
  description: {
    required: helpers.withMessage('This field cannot be empty', required),
  }
};

export {
  notificationsFormRules
}; 